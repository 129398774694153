// src\components\app\App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import { Helmet } from 'react-helmet';

import '@mantine/core/styles.css';
//import './less/app.less';

//react+redux+axios+mantine+blockly
function App() {
    //const test = useSelector((state) => state.settings.test);

    return (
        <>
            <Helmet>
                <link
                    rel="preload"
                    href={`${process.env.PUBLIC_URL}/fonts/Lora-VariableFont_wght.ttf`}
                    as="font"
                    type="font/ttf"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    href={`${process.env.PUBLIC_URL}/fonts/Lora-Italic-VariableFont_wght.ttf`}
                    as="font"
                    type="font/ttf"
                    crossOrigin="anonymous"
                />
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
