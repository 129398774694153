import React from 'react';
import { useSelector } from 'react-redux';
import { useStoreName, useParentStoreName } from '@context/StoreNameContext';
import withStoreName from '@context/withStoreName';
import createMenuSlice from '@slices/menuSlice';
import { useDynamicReducer } from '@helpers/useDynamicReducer';
import data from './data';
//import './css/menu.css';
//import './css/menu.mobile.css';
//import './less/menu.less';

const Menu = ({ children }) => {
    const storeName = useStoreName();
    const parentStoreName = useParentStoreName();
    useDynamicReducer(storeName, createMenuSlice, parentStoreName);

    const storeData = useSelector((state) => state[storeName]?.storeName);

    const renderMenu = (menuItems) => {
        return (
            <ul>
                {menuItems.map((item) => (
                    <li key={item.id}>
                        <a href={item.link} title={item.label}>
                            {item.label}
                        </a>
                        {item.subMenu &&
                            item.subMenu.length > 0 &&
                            renderMenu(item.subMenu)}
                    </li>
                ))}
                <li>
                    <a className="callback" href="#">
                        Обратный звонок
                    </a>
                </li>
            </ul>
        );
    };

    return (
        <nav aria-label={`Menu for ${storeData}`}>
            <div className="logo">
                {/*<div className="socials" Icons for social media </div>*/}
                <div className="nav-logo">Вкалывают роботы</div>
                {/*<div className="contacts">
                     Contact information and callback button 
                </div>*/}
            </div>
            {renderMenu(data)}
            {children}
        </nav>
    );
};

export default withStoreName(Menu);
