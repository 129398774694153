//import BlocklyComponent, {Block, Value, Field, Shadow} from './Blockly';
import { Grid, Title, Paper, Text, List, ThemeIcon, rem } from '@mantine/core';
import { StoreNameProvider } from '@context/StoreNameContext';
import React from 'react';

import Section from '@components/section';
import Menu from '@components/menu';
import '@assets/main.less';

function HomePage() {
    return (
        <>
            <Section storeName="section-1" className="section-header">
                <Menu storeName="menu" />
            </Section>
            <Section storeName="section-2">222</Section>
            <Section storeName="section-3">3333</Section>
            <Section storeName="section-4" className="section-dark">
                444
            </Section>
            <Section storeName="section-5">555</Section>
            <Section
                storeName="section-6"
                className="section-footer section-dark"
            >
                666
            </Section>
        </>
    );
}

export default HomePage;
