const data = [
    {
        id: 'main',
        label: 'Главная',
        link: '/',
        subMenu: [],
    },
    {
        id: 'test',
        label: 'test',
        link: '#',
        subMenu: [],
    },
    {
        id: 'test',
        label: 'test',
        link: '#',
        subMenu: [],
    },
    {
        id: 'test',
        label: 'test',
        link: '#',
        subMenu: [],
    },
    {
        id: 'test',
        label: 'test',
        link: '#',
        subMenu: [],
    },
];

export default data;
